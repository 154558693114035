import type {ComponentType} from 'react';
import type {Subscription} from 'rxjs';

import PopupService from '@core/popup/utils/PopupService';
import type {PaymentDataResponse} from '@core/payment/payProcess/types/paymentFlow';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import trackPaymentPendingPageStep from '@core/tracking/trackPaymentPendingPageStep/trackPaymentPendingPageStep';
import type {Action, ViaEnum} from '@core/types/graphql';

import openThreeDSecurePopup from '../../widgets/threeDSecure/utils/openThreeDSecurePopup';
import openThreeDSecureAbortPopup from '../../widgets/threeDSecure/utils/openThreeDSecureAbortPopup';
import type ThreeDSecureAbortPopupProps from '../../widgets/threeDSecure/types/threeDSecureAbortPopupProps';
import openPendingPopup from '../../paymentPending/utils/openPendingPopup';
import createPostMessageObservable from '../../widgets/threeDSecure/utils/createPostMessageObservable';
import createUserPaymentProcessedObservable from '../../widgets/threeDSecure/utils/createUserPaymentProcessedObservable';
import createThreeDSStatusCheckObservable from '../../paymentPending/utils/createThreeDSStatusCheckObservable';
import createVisibilityChangeObservable from '../../paymentPending/utils/createVisibilityChangeObservable';
import spaCancelPayment from '../../widgets/threeDSecure/utils/spaCancelPayment';
import {PaymentFlowStatus} from './getProcessPaymentFlow';
import PAYMENT_STATUSES from '../../paymentPending/constants/paymentStatuses';
import STEPS from '../../paymentPending/constants/steps';

type ThreeDSecureProcessorResult =
  | (PaymentDataResponse & {
      via?: ViaEnum;
    })
  | PaymentFlowStatus;

export default class ThreeDSecureProcessor {
  PaymentPendingPopupLayout: ComponentType;

  ThreeDSecureAbortPopup: ComponentType<ThreeDSecureAbortPopupProps>;

  postMessageObservable$: Subscription;

  userPaymentProcessedObservable$: Subscription;

  threeDSStatusCheckObservable$: Subscription;

  visibilityChangeObservable$: Subscription;

  via: ViaEnum;

  orderId: string;

  promise: Promise<ThreeDSecureProcessorResult>;

  resolvePromise: (answer: ThreeDSecureProcessorResult) => void;

  constructor(
    via: ViaEnum,
    orderId: string,
    PaymentPendingPopupLayout: ComponentType,
    ThreeDSecureAbortPopup: ComponentType<ThreeDSecureAbortPopupProps>,
  ) {
    this.via = via;
    this.orderId = orderId;
    this.PaymentPendingPopupLayout = PaymentPendingPopupLayout;
    this.ThreeDSecureAbortPopup = ThreeDSecureAbortPopup;
    this.promise = new Promise((resolvePromise) => {
      this.resolvePromise = resolvePromise;
    });
  }

  subscribePostMessage(): void {
    if (this.postMessageObservable$) {
      return;
    }

    this.postMessageObservable$ = createPostMessageObservable().subscribe(
      (data) => {
        PopupService.closePopup(true);

        const {status} = data;

        if (status === PAYMENT_STATUSES.PENDING) {
          this.openPendingPopup();
          return;
        }

        this.resolve({
          ...data,
          via: this.via,
          orderId: this.orderId,
          status: status === PAYMENT_STATUSES.SUCCESS,
        });
      },
    );
  }

  subscribeUserPaymentProcessed(): void {
    if (this.userPaymentProcessedObservable$) {
      return;
    }

    this.userPaymentProcessedObservable$ =
      createUserPaymentProcessedObservable().subscribe((data) => {
        PopupService.closePopup(true);

        const {redirectUrl, via, orderId, paymentStatus} = data;
        const {searchParams, origin, pathname} = new URL(
          redirectUrl,
          window.location.toString(),
        );
        searchParams.set('via', via);
        searchParams.set('orderId', orderId);

        this.resolve({
          ...data,
          status: paymentStatus === PAYMENT_STATUSES.SUCCESS,
          redirectUrl: `${origin}${pathname}?${searchParams}`,
        });
      });
  }

  openThreeDSecurePopup(iframeUrl?: string): this {
    this.subscribePostMessage();
    this.subscribeUserPaymentProcessed();

    openThreeDSecurePopup({
      iframeUrl,
      onCloseClick: (sourceEventType) => {
        if (
          sourceEventType === PopupSourceEvent.CLOSE_BUTTON_CLICK ||
          sourceEventType === PopupSourceEvent.BY_ESCAPE
        ) {
          openThreeDSecureAbortPopup({
            ThreeDSecureAbortPopup: this.ThreeDSecureAbortPopup,
            abortPayment: () => {
              spaCancelPayment();
              this.resolve(PaymentFlowStatus.ABORTED);
            },
          });

          /**
           * Prevent close
           */
          return true;
        }

        return false;
      },
    });

    return this;
  }

  openPendingPopup(): this {
    this.subscribeUserPaymentProcessed();

    openPendingPopup(this.PaymentPendingPopupLayout);

    trackPaymentPendingPageStep({
      orderId: this.orderId,
      step: STEPS.DISPLAYED,
    });

    this.visibilityChangeObservable$ =
      createVisibilityChangeObservable().subscribe((visibilityState) =>
        trackPaymentPendingPageStep({
          orderId: this.orderId,
          step:
            visibilityState === 'visible' ? STEPS.APPEARED : STEPS.DISAPPEARED,
        }),
      );

    this.threeDSStatusCheckObservable$ = createThreeDSStatusCheckObservable(
      this.via as unknown as Action,
      this.orderId,
    ).subscribe((statusData) => {
      PopupService.closePopup(true);

      this.resolve({
        ...statusData,
        via: this.via,
        orderId: this.orderId,
        status: statusData.status === PAYMENT_STATUSES.SUCCESS,
      });
    });

    return this;
  }

  unsubscribe(): void {
    this.postMessageObservable$?.unsubscribe();
    this.userPaymentProcessedObservable$?.unsubscribe();
    this.threeDSStatusCheckObservable$?.unsubscribe();
    this.visibilityChangeObservable$?.unsubscribe();
  }

  resolve(answer: ThreeDSecureProcessorResult): void {
    this.unsubscribe();
    this.resolvePromise(answer);
  }

  getPromise(): Promise<ThreeDSecureProcessorResult> {
    return this.promise;
  }
}
