import type {ViaEnum} from '@core/types/graphql';

import {Event} from '../constants/babciaDataTypes';
import createRouteName from './createRouteName';
import sendUBEventToBabcia from './sendUBEventToBabcia';

type Location = {
  pathname?: string;
  search?: string;
};

type TrackRedirectToParams = {
  nextLocation?: Location;
  nextPathname?: string;
  currentPathname?: string;
  event?: Event;
};

const getVia = (location?: Location, pathname?: string): ViaEnum | null => {
  // Check if url is absolute or relative
  if (/^https?:\/\//i.test(pathname)) {
    return null;
  }

  if (pathname) {
    return new URL(window.location.origin + pathname).searchParams.get(
      'via',
    ) as ViaEnum | null;
  }

  if (location?.search) {
    return new URLSearchParams(location.search).get('via') as ViaEnum | null;
  }

  return null;
};

/**
 * Do not pass 'nextLocation' and 'nextPathname' at once, only one of these options is required!
 * 'currentPathname' is required for cases when the redirect is tracked after going to the next page!
 *
 * Example use:
 *
 * trackRedirectTo({nextPathname: '/baz'});
 *
 * or
 *
 * trackRedirectTo({
 *   nextLocation: {
 *     pathname: '/baz',
 *     search: '?someParam=bar',
 *   },
 * });
 */
const trackRedirectTo = ({
  nextLocation,
  nextPathname,
  currentPathname,
  event = Event.REDIRECT,
}: TrackRedirectToParams): void => {
  sendUBEventToBabcia({
    pathname: currentPathname,
    event,
    via: getVia(nextLocation, nextPathname),
    value: createRouteName(nextPathname || nextLocation.pathname),
  });
};

export default trackRedirectTo;
