import {makeVar} from '@apollo/client';

import type {ViaEnum} from '@core/types/graphql';

type PaymentTemplateSettings = {
  action: string | null;
  via: ViaEnum | null;
  template: string | null;
  motivationTemplate: string | null;
  isComplianceFriendly: boolean | null;
  loading: boolean;
};

export const defaultValues: PaymentTemplateSettings = {
  action: null,
  via: null,
  template: null,
  motivationTemplate: null,
  isComplianceFriendly: null,
  loading: false,
};

const restApiPaymentTemplateSettingsVar = makeVar(defaultValues);

export default restApiPaymentTemplateSettingsVar;
