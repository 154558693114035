import memoizeOne from 'memoize-one';

import logger from '@core/logger';
import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import backboneModelPay from '@core/payment/payProcess/utils/backboneModelPay';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import getMethodScenario from '@core/payment/payProcess/utils/getMethodScenario';
import isCardMethods from '@core/payment/payProcess/utils/isCardMethods';

/**
 * DISCLAIMER: all methods below used to pay in react app with Backbone models, it's temporary solution
 * This code must be removed after implementation payment method with GraphQl mutation
 */

/**
 * Method for make payment with Backbone model,
 * Takes params for crete model, and return save model callback, that in its turn return payment result
 * @TODO Should be rewritten on GraphQL realization instead of Backbone
 * @param {string} method
 * @param {string} action
 * @param {ViaEnum} prevVia
 * @param {boolean} withRedirectPayment
 * @param {string} formName
 * @param {boolean} payFromPopup
 * @param {Array} requiredFields
 * @deprecated
 * @returns {function(*=): {}}
 */
const getPayMethodWithBackboneModel = memoizeOne(
  ({
    method,
    action,
    prevVia,
    payFromPopup = false,
    formName,
    withRedirectPayment,
    altMethodsSettings,
  }) => {
    // payment result false by default
    let result = {status: false};

    /**
     * save model to server and set result
     * @type {function(*=, *): Promise<void>}
     */
    const payWithBackboneModel = async (params, scenario) => {
      try {
        // @ts-expect-error Backbone legacy
        result = await backboneModelPay({
          method,
          action,
          scenario,
          params,
          prevVia,
          formName,
          withRedirectPayment,
          altMethodsSettings,
        });
      } catch (error) {
        logger.sendError(
          `[getPayMethodWithBackboneModel] backboneModelPay error ${error}`,
        );
      }
    };

    /**
     * @param {object} params
     * @return {Promise<{}>}
     */
    const payWithCard = async (params) => {
      // For card payments from popup need check oneClick availability and redirect to pp after decline
      if (payFromPopup) {
        if (
          await isCardOneClickAllowed({
            source: PAYMENT_SOURCES.POPUP,
            via: params.prevVia,
          })
        ) {
          await payWithBackboneModel(params, PaymentScenario.ONECLICK);
        }

        return result;
      }

      const scenario =
        params.hidePaymentForm || params.oneClickFlow
          ? PaymentScenario.ONECLICK
          : PaymentScenario.INITIAL;

      await payWithBackboneModel(params, scenario);

      return result;
    };

    /**
     * Get selected method and process payment with method scenario
     * @param {object} params
     * @return {Promise<{}>}
     */
    const payWithAltMethod = async (params) => {
      const scenario = await getMethodScenario(action, params.prevVia, method);

      switch (method) {
        case PAYMENT_METHODS.NOVAL_NET_SEPA:
        case PAYMENT_METHODS.VENDO_SEPA:
        case PAYMENT_METHODS.TRUST_PAY_SEPA:
        case PAYMENT_METHODS.SALT_EDGE_AIS:
        case PAYMENT_METHODS.COMM_DOO_SEPA:
        case PAYMENT_METHODS.MICRO_PAYMENT_SEPA:
          // For popup pay with no oneClick redirect to payment pages
          if (payFromPopup && scenario === PaymentScenario.INITIAL) {
            return {
              needRedirectToPP: true,
            };
          }

          await payWithBackboneModel(params, scenario);

          return result;
        // Methods with payment via direct request
        case PAYMENT_METHODS.PAY_PAL:
        case PAYMENT_METHODS.PAY_PAL_V2:
        case PAYMENT_METHODS.SOFORT:
        case PAYMENT_METHODS.CCBILL:
        case PAYMENT_METHODS.APPLE_PAY:
        case PAYMENT_METHODS.KONBINI:
        case PAYMENT_METHODS.GOOGLE_PAY:
        case PAYMENT_METHODS.NUVEI_IDEAL:
        case PAYMENT_METHODS.NUVEI_MY_BANK:
        case PAYMENT_METHODS.TRUST_PAY_IDEAL:
          await payWithBackboneModel(params, scenario);
          return result;
        default:
          logger.sendError(
            `[getPayMethodWithBackboneModel] Wrong method "${method}" for performing pay operation. Probably is a new method or you passed wrong name.`,
          );
          return result;
      }
    };

    /**
     * Process pay with selected method
     * Return result - payment answer or redirect flag
     * @param {object} params - payment params
     * @param {String} params.package_id - stockId, required
     * @param {String} params.prevVia - payment via
     * @param {Number} params.hidePaymentForm - is oneClick by card
     * ... And other payment params
     * @return {Promise<{}>}
     */
    return async (params) => {
      if (isCardMethods(method)) {
        return payWithCard(params);
      }

      return payWithAltMethod(params);
    };
  },
);

export default getPayMethodWithBackboneModel;
