import {ViaEnum} from '@core/types/graphql';

/**
 * @const {Object} - vias for app funnel
 */
export const PAY_APP_FUNNEL_VIAS = {
  FIRST_VIA: ViaEnum.ftcard_1st,
  SECOND_VIA: ViaEnum.ftcard_2st,
  NEO_BANK_FIRST_VIA: ViaEnum.ftcard_1st_special,
  NEO_BANK_SECOND_VIA: ViaEnum.ftcard_2st_lifetime,
  FT_CARD_TRIAL: ViaEnum.ftcard_trial,
  FT_CARD_PP: ViaEnum.ftcard_pp,
};

/**
 * @const {Array} - vias for extra day
 */
export const EXTRA_DAY_VIAS = [ViaEnum.extraDayPopup, ViaEnum.spin_extraDay];

/**
 * @const {Array} - vias with selected package
 */
export const VIA_WITH_SELECTED_PACKAGE = [
  ...EXTRA_DAY_VIAS,
  ViaEnum.buy3DayGet1WeekPopup,
];
