import isEmpty from 'lodash/isEmpty';

import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';
import CURRENT_USER_COUNTRY_QUERY from '@core/user/profile/current/graphql/queries/currentUserCountry.gql';
import type {
  CurrentUserCountryQuery,
  CurrentUserCountryQueryVariables,
} from '@core/user/profile/current/graphql/queries/currentUserCountry';
import type {PaymentDataRequest} from '@core/payment/payProcess/types/paymentFlow';
import type {ViaEnum} from '@core/types/graphql';

import type {PaymentProcessStep} from '../../types/paymentProcess';
import {WALLET_METHODS} from '../../../common/constants/paymentMethods';

const ALLOWED_WALLET_METHODS: string[] = [
  WALLET_METHODS.APPLE_PAY,
  WALLET_METHODS.GOOGLE_PAY,
];

/**
 * Add base environment data(selected package, user info) to the form data
 */
export const addPaymentDataInner = async (
  paymentData: Partial<PaymentDataRequest>,
): Promise<PaymentDataRequest> => {
  const {
    action,
    activePackage,
    altMethodsSettings,
    isInstantRepeat,
    selectedAdditionalPackages,
    form,
    method,
    returnPath,
    replaceRedirectToPostMessage,
    via,
    withRedirectPayment,
  } = paymentData;

  /**
   * @important We need to replace card method by wallet methods
   */
  const computedMethod = method || activePackage.paymentMethod;

  const isWalletMethods = ALLOWED_WALLET_METHODS.includes(method);

  const extendedData = {
    ...form,
    action,
    withRedirectPayment: withRedirectPayment || false,
    returnPath,
    via: action as ViaEnum,
    prevVia: via,
    method: computedMethod,
    stockId: activePackage.stockId,
    packageId: activePackage.packageId,
    title: activePackage.title,
    altMethodsSettings,
    replaceRedirectToPostMessage: replaceRedirectToPostMessage || false,
  };

  if (!isEmpty(selectedAdditionalPackages)) {
    extendedData.selectedAdditionalPackages = selectedAdditionalPackages;
  }

  const {data} = await getClientInstance().query<
    CurrentUserCountryQuery,
    CurrentUserCountryQueryVariables
  >({
    query: CURRENT_USER_COUNTRY_QUERY,
  });

  if (!data) {
    logger.sendError('[addPaymentData] CurrentUserCountry read failed');
    return extendedData;
  }

  return {
    ...extendedData,
    // TODO[BB_removed]: check for what payment methods need country param
    country: data.myUser.profile.location.country,
    ...(isWalletMethods ? {user_id: data.myUser.profile.id} : {}),
    ...(isInstantRepeat ? {isInstantRepeat} : {}),
  };
};

export const addPaymentData: PaymentProcessStep = async (data) => {
  const paymentDataModified = await addPaymentDataInner(data.paymentData);

  return {
    ...data,
    paymentData: paymentDataModified,
  };
};

export default addPaymentData;
