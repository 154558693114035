import {useMemo, useEffect} from 'react';

import URI from '@core/utils/url';
import logger from '@core/logger';
import {ViaEnum} from '@core/types/graphql';

import usePaymentParams from './usePaymentParams';
import usePaymentTemplate from './usePaymentTemplate';

/**
 * Checks is error message matches VieEnum field error
 * @param via {ViaEnum} vis parameter
 * @param message {string} error message
 * @return {boolean}
 */
const isInvalidViaError = (via, message = '') =>
  RegExp(`via.+${via}.+ViaEnum`, 'gi').test(message);

/**
 * Get error redirect URL
 * @param options {{ error: ApolloError, via: ViaEnum }}
 * @return {string}
 */
export const getErrorRedirect = ({error, via}) => {
  if (error && via && isInvalidViaError(via, error.message)) {
    const uri = URI().setSearch({via: ViaEnum.unknown});

    logger.sendWarning(`[getErrorRedirect] Unknown via "${via}" in url`);

    return `${uri.pathname()}${uri.search()}`;
  }

  return '';
};

/**
 * Check is via valid and exist in ViaEnum in GQL schema
 * @return {{redirectUrl: string, loading: boolean}}
 */
const useValidateVia = () => {
  const {error, loading} = usePaymentTemplate();
  const {via} = usePaymentParams();
  const redirectUrl = useMemo(
    () => getErrorRedirect({error, via}),
    [error, via],
  );

  // Log if no via in URL
  useEffect(() => {
    if (!via) {
      logger.sendWarning('[useValidateVia] No via in url');
    }
  }, [via]);

  return {
    loading,
    redirectUrl,
  };
};

export default useValidateVia;
