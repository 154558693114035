import fetch from 'unfetch';
import values from 'lodash/values';
import last from 'lodash/last';

import {ViaEnum} from '@core/types/graphql';
import {VIA_USER_STORIES} from '@core/stories/uploadPopup/constants/storyUploadPopup';
import sendToGTM from '@core/gtm/utils/sendToGTM';
import EventName from '@core/gtm/constants/EventName';

import getPhotoErrorMessage from '../getPhotoErrorMessage';
import putPhotoInCache from '../putPhotoInCache';

/**
 * @param via {ViaEnum}
 * @param attachToUser {String}
 * @param notifySuccess {Boolean}
 * @param country {String} - @see openPersonalIdentityByDemandDocumentUploadPopup.js
 */
const getUploadUrl = ({via, attachToUser, country, notifySuccess = true}) => {
  switch (via) {
    case ViaEnum.verification:
      return `/api/v1/personalIdentity/uploadFile?via=${via}&attachToUser=${attachToUser}${
        country ? `&country=${country}` : ''
      }`;
    case VIA_USER_STORIES:
      return `/api/v1/userStories/photoFragmentUpload?via=${via}&attachToUser=${attachToUser}`;
    default:
      return `/api/v1/photo/upload?via=${via}&attachToUser=${attachToUser}&notifySuccess=${notifySuccess}`;
  }
};

const getInputName = ({via}) => {
  switch (via) {
    case ViaEnum.verification:
      return 'personal-document';
    default:
      return 'PhotoUploadForm[file]';
  }
};

/**
 * Upload photo
 * @param {Object} props
 * @param {Blob} props.file - Photo file
 * @param {ViaEnum} props.via - Via to upload with. @see viaList
 * @param {Boolean} props.attachToUser - Should it be attached to profile
 * @param {Boolean} props.notifySuccess - Need to send success socket
 * @param {Boolean} props.country - Country for personal identity document upload. @see dating/personalIdentityByDemand/utils/openDocumentUploadPopup.js
 * @returns {Promise}
 */
const uploadPhoto = ({
  file,
  via,
  attachToUser,
  country,
  notifySuccess = true,
}) => {
  const formData = new FormData();
  formData.append(getInputName({via}), file);

  return fetch(getUploadUrl({via, attachToUser, country, notifySuccess}), {
    method: 'POST',
    body: formData,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then((response) => response.json())
    .then(({data, meta, status}) => {
      if (status === 'error') {
        const {
          description: {message, general, error},
        } = meta;
        return Promise.reject(
          new Error(
            message ||
              getPhotoErrorMessage(error, file.name) ||
              getPhotoErrorMessage(last(values(general)), file.name),
          ),
        );
      }

      if (attachToUser) {
        return putPhotoInCache(data)
          .then(() => {
            if (via === ViaEnum.update_profile) {
              sendToGTM({
                eventName: EventName.ADD_PROFILE_PHOTO,
              });
            }
          })
          .then(() => data);
      }

      return data;
    });
};

export default uploadPhoto;
