import uniqueId from 'lodash/uniqueId';
import defer from 'lodash/defer';

import {ViaEnum} from '@core/types/graphql';
import {removeVideoFromCache} from '@core/user/profile/current/utils/cacheUpdaters';
import sendToGTM from '@core/gtm/utils/sendToGTM';
import EventName from '@core/gtm/constants/EventName';

import updateVideoCache from './updateVideoCache';
import putVideoInCache from './putVideoInCache';

const UPLOAD_SHARE = 0.99;

/**
 * Utility used to update profile "uploading" placeholder when attachToUser is truthy.
 *
 * @param {Object} props
 * @param {Promise} props.promise - Video uploading promise
 * @param {rxjs/Observable} props.progress$ - Observable to watch uploading progress
 */
export default function putVideoInCacheSubscription({promise, progress$, via}) {
  const uploadId = uniqueId('putVideoInCacheSubscription');

  putVideoInCache({id: uploadId});

  const progressSubscription = progress$.subscribe((share) => {
    updateVideoCache({
      id: uploadId,
      percentage: Math.round(share * UPLOAD_SHARE * 100),
    });
  });

  promise.then(({result, error}) => {
    progressSubscription.unsubscribe();
    if (result) {
      if (via === ViaEnum.update_profile) {
        sendToGTM({
          eventName: EventName.ADD_PROFILE_VIDEO,
        });
      }
      putVideoInCache({...result, uploadId, percentage: UPLOAD_SHARE * 100});
    } else if (error) {
      // Defer update to not collide with share update when 502 error
      defer(() => removeVideoFromCache({id: uploadId}));
    }
  });
}
