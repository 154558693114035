import getTheme from '@core/application/utils/getTheme';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {CURRENT_USER_ID} from '@core/application/constants/bootstrapParams';
import getHistory from '@core/application/utils/getHistory';
import type {ViaEnum} from '@core/types/graphql';

import type {Event} from '../constants/babciaDataTypes';
import {
  Type,
  EventAttributes,
  EventType,
  Category,
} from '../constants/babciaDataTypes';
import createRouteName from './createRouteName';
import {TRACKING_ID} from '../constants/babcia';
import babciaDebugLog from './babciaDebugLog';

const themeName = getTheme().name;
let userId = getBootstrapParam(CURRENT_USER_ID);

type FormatDataParams = Partial<{
  event: Event;
  category: Category;
  label: string;
  value: string;
  context: string;
  index: string;
  conceptId: string;
  eventType: EventType;
  via: ViaEnum;
  timestamp: number;
  pathname: string;
}>;

type SendUBEventToBabciaParams = FormatDataParams & {
  debugInfo?: string;
};

export const formatData = (
  {
    event,
    category = Category.PAGE,
    label,
    value,
    context,
    index,
    conceptId,
    eventType = EventType.MAIN,
    via,
    timestamp,
    pathname,
  }: FormatDataParams = {} as FormatDataParams,
) => {
  const result = {
    event,
    category,
    label: String(label || EventAttributes.NONE),
    type: Type.EVENT,
    value: String(value || EventAttributes.NONE),
    timestamp,
    eventAttributes: [] as string[],
    userIdentities: [] as string[],
  };

  // ThemeID, SitePage, ConceptID, EventType, VIAname, Context, Index
  result.eventAttributes = [
    themeName,
    createRouteName(pathname || getHistory().location.pathname),
    conceptId ? String(conceptId) : EventAttributes.NONE,
    eventType,
    via ? String(via) : EventAttributes.NONE,
    context ? String(context) : EventAttributes.NONE,
    index ? String(index) : EventAttributes.NONE,
  ];

  userId ||= getBootstrapParam(CURRENT_USER_ID);

  if (userId) {
    result.userIdentities = [String(userId)];
  }

  return result;
};

/**
 * Function for send user behavior events to the Babcia
 * @param debugInfo - Text for graphical debug
 * @param data - The data to push
 */
const sendUBEventToBabcia = (
  {debugInfo, ...data}: SendUBEventToBabciaParams,
  element?: HTMLElement,
): void => {
  if (window[TRACKING_ID.USER_BEHAVIOR]) {
    const formatted = formatData(data);

    window[TRACKING_ID.USER_BEHAVIOR].push(formatted);

    babciaDebugLog({element, ...formatted, debugInfo});
  }
};

export default sendUBEventToBabcia;
