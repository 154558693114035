import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';

import getHistory from '@core/application/utils/getHistory';
import logger from '@core/logger';
import isPayUrl from '@core/utils/url/isPayUrl';
import clearUrl from '@core/utils/url/clearUrl';
import {ViaEnum} from '@core/types/graphql';

type UrlParams =
  | string
  | {
      0: string;
      via: ViaEnum;
      orderId: string;
    };

const safeRedirect = (url: UrlParams) => {
  let href: string;

  if (isNil(url)) {
    logger.sendWarning('Can not go to undefined URL');
  } else {
    /**
     * @TODO[BB_removed]: Find out from BU why we need object or array in url.
     * If they approve it, create a task to them.
     */
    if (isObject(url)) {
      href = `${url[0]}?via=${url.via ?? ViaEnum.unknown}${url.orderId ? `&orderId=${url.orderId}` : ''}`;
    } else {
      href = url;
    }

    const clearedUrl = clearUrl(href);

    if (isPayUrl(href)) {
      getHistory().push(clearedUrl);
    } else if (
      /**
       * @important Check for avoiding cyclic redirect
       */
      window.location.pathname !== `/${clearedUrl}`
    ) {
      window.location.assign(href);
    }
  }
};

export default safeRedirect;
