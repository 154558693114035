import {ViaEnum} from '@core/types/graphql';

/**
 * List of 'via' values that trigger in-page decline actions
 */
export const INPAGE_DECLINE_VIAS = [
  ViaEnum.repeat_inpage_decline,
  ViaEnum.inpage_decline,
];

/**
 * List of 'via' values that trigger an error message with a SEPA method
 */
export default [
  ViaEnum.sepa_off,
  ViaEnum.repeat_inpage_decline,
  ViaEnum.inpage_decline,
];
