import {getClientInstance} from '@core/graphql/client';
import {localStorage} from '@core/utils/storage';

import PAYMENT_DESCRIPTOR_QUERY from '../graphql/queries/paymentDescriptor.gql';
import PAYMENT_SOURCES from '../../../common/constants/paymentSources';

const STORAGE_PREFIX = 'descriptor';

const getStorageKey = (userId) => `${STORAGE_PREFIX}:${userId}`;

/**
 * It caches request data in local storage by variables on frontend
 * @param {String} userId
 * @param {Boolean} skipRequestIfNeed
 * @param {ViaEnum} via
 * @param {String} stockId
 * @param {String} orderId
 * @param {Boolean} isOneClickUsed
 * @param {String} source
 * @param {String} bin
 * @param {String} cardId
 * @return {Promise}
 */
const getCachedPaymentDescriptor = async ({
  userId,
  skipRequestIfNeed = false,
  via,
  stockId,
  orderId,
  isOneClickUsed,
  source,
  bin,
  cardId,
}) => {
  if (!userId || skipRequestIfNeed) {
    return {
      data: null,
      error: null,
    };
  }

  const variables = {
    via,
    stockId,
    orderId,
    isOneClickUsed,
    isPopupPayment: source === PAYMENT_SOURCES.POPUP,
    bin,
    cardId,
  };
  const key = btoa(JSON.stringify(variables));
  const storageKey = getStorageKey(userId);
  const cached = localStorage.getItem(storageKey, {});
  if (cached.key === key) {
    return {
      data: cached.data,
      error: null,
    };
  }

  try {
    const {data} = await getClientInstance().query({
      query: PAYMENT_DESCRIPTOR_QUERY,
      variables,
    });

    localStorage.setItem(storageKey, {
      key,
      data,
    });
    return {
      data,
      error: null,
    };
  } catch (error) {
    localStorage.removeItem(storageKey);
    return {
      data: null,
      error: `[getCachedPaymentDescriptor] can't get description data: ${error}`,
    };
  }
};

export default getCachedPaymentDescriptor;
