type SplitParams = {
  GROUP: {
    ACTIVE: number;
    INACTIVE: number;
  };
  ID: string;
};

type PayAppFunnelPageNewMotivationParams = {
  GROUP: {
    INACTIVE: number;
    ONE: number;
    TWO: number;
  };
  ID: string;
};

/** Split maintenance.20240514.kushnir.FE_38233
 */
const WEB_PUSH_CUSTOM_POPUPS: SplitParams = {
  ID: 'a8099be49873890e4c5822b734dd05c1',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split maintenance.20230816.sarkisian.FE_33169
 */
const UNITED_ACTIVITY: SplitParams = {
  ID: 'c06f52f4487d4e1c5691a0932743d1e9',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split maintenance.20240926.golubkov.FE_41698
 * @type {{GROUP: {ACTIVE: number, INACTIVE: number}, ID: string}}
 */
const ZOOMED_PRODUCT = {
  ID: 'ad451232fcab8c3333574fff1c4576e4',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split payment.20211016.mironiuk.BU_65885
 */
const PAYMENT_AUTOFOCUS: SplitParams = {
  ID: '18f3ce746e7a99c8168b7268eb615a9f',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split payment.20220131.psareva.FE_20680
 */
const PAYMENT_PACKAGES_EXPERIMENT_PART_2: SplitParams = {
  ID: '5b89cdfcb55fed90c307f92beb304a42',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split payment.20220629.psareva.FE_24253
 */
const CANCEL_BILLING_SUPPORT_PHONE: SplitParams = {
  ID: '43a9596db3bc87eed8a361163466672b',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

const BUY_SECURELY_PAY_BUTTON: SplitParams = {
  ID: '029802bc49afcb2c721077878941cd75',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/** Split maintenance.20221114.poliakov.BU_175028
 */
const SEPA_ALTERNATIVE_FORMAT: SplitParams = {
  ID: '5e3024e73bf7027af9014ebce313273c',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Split apps.20230503.gut.APP_21139
 */
const PAY_APP_FUNNEL_PAGE_NEW_MOTIVATION: PayAppFunnelPageNewMotivationParams =
  {
    ID: 'd954839cec1d79b2b7f72de27c334b40',
    GROUP: {
      INACTIVE: 0,
      ONE: 1,
      TWO: 2,
    },
  };

/**
 * User can view only first video on open profile
 * maintenance.20250129.voloshin.TN_213342
 */
const OPEN_ONLY_FIRST_VIDEO_IN_PROFILE: SplitParams = {
  ID: '54da8b7a3ffb44617eb16616b183c831',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Test split new payment processing with Fetch API
 * payment.20250305.vavrik.FE_43086
 */
const PAYMENT_PROCESSING_FETCH_API = {
  ID: '9302a69985b5f7eb440b56171f0f71bd',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * Dating beyond borders feature based on map
 * core.20240508.bilenko.PAT_5960
 */
const SEARCH_WITH_MAP: SplitParams = {
  ID: 'a5a5a7b82c2b8e50d8cf52c173889a58',
  GROUP: {
    INACTIVE: 0,
    ACTIVE: 1,
  },
};

/**
 * List of splits and user groups
 */
export default {
  WEB_PUSH_CUSTOM_POPUPS,
  UNITED_ACTIVITY,
  ZOOMED_PRODUCT,
  BUY_SECURELY_PAY_BUTTON,
  PAYMENT_AUTOFOCUS,
  CANCEL_BILLING_SUPPORT_PHONE,
  PAYMENT_PACKAGES_EXPERIMENT_PART_2,
  SEPA_ALTERNATIVE_FORMAT,
  PAY_APP_FUNNEL_PAGE_NEW_MOTIVATION,
  OPEN_ONLY_FIRST_VIDEO_IN_PROFILE,
  PAYMENT_PROCESSING_FETCH_API,
  SEARCH_WITH_MAP,
};
